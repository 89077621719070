@import "../../Variables.less";

.layout-header {
  padding: 0 8px;
  background: #000;
  margin-bottom: 16px;
}

.logo {
  height: 55px;
}

.breadcrumb {
  margin: 16px 24px;
}

.site-layout-content {
  min-height: 280px;
}

@primary-color: #89A64F;@layout-body-background: #edf2e3;@layout-header-background: #89A64F;