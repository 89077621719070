@submissionColour: rgba(0, 0, 0, 0.8);

.submissionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

@primary-color: #89A64F;@layout-body-background: #edf2e3;@layout-header-background: #89A64F;