.actions {
  margin-bottom: 0;

  // bit haxor. how does it know the parent is flex?
  flex: 1 1 auto;

  .next-button {
    float: right;
  }

  .custom-button {
    align-self: center;
  }
}

@primary-color: #89A64F;@layout-body-background: #edf2e3;@layout-header-background: #89A64F;