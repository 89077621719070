.trailer-photo {
  .sample {
    width: 120px;
    margin-bottom: 24px;
  }
  .preview {
    width: 80%;
    margin-bottom: 16px;
    border: 1px solid #ccc;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-upload {
      display: flex;
      flex-direction: column;

      .custom-button {
        margin-bottom: 16px;
        align-self: stretch;
      }
    }
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
}

@primary-color: #89A64F;@layout-body-background: #edf2e3;@layout-header-background: #89A64F;